/*
 * PACKAGES
 */
export const _initialState = {
    tableFilter: null ,
    tableFilterScope: null
};
  
  /*
   * OBJECTS
   */
  const _Analytics = (__prevState = _initialState, __action) => {
    // console.log(__action, _initialState, __prevState)
    /*
     * Switch case for handling actions on
     * local store.
     */
    switch (__action.type) {
      case "ANALYTICS_UPDATE":
        // Return combined state.
        return {
          ...__prevState,
          ...__action.Analytics,
        };
      case "ANALYTICS_CLEAR":
        // Return combined state.
        return {
          ..._initialState,
        };
      default:
        // Return old state.
        return __prevState;
    }
  }
  
  /*
   * EXPORTS
   */
  export default _Analytics;
  